<template>
  <el-form @submit.prevent>
    <div class="login_zone input_form">
      <div class="other_login">
        <div class="icon"><img class="otherBtn" @click="lineLogin()" :src="require('/src/assets/image/Shared/Login/Single_Line.png')" /></div>
        <div class="icon"><img class="otherBtn" @click="signInWithGoogle()" :src="require('/src/assets/image/Shared/Login/Single_Google.png')" /></div>
        <!-- <div class="icon"><img class="otherBtn" @click="loginWithFaceBook()" :src="require('/src/assets/image/Shared/Login/Single_Facebook.png')" /></div> -->
      </div>
      <el-button class="mt-3" data-bs-toggle="collapse" data-bs-target="#showOtherLogin">
        <span>其他登入方式</span>
      </el-button>
      <div class="collapse" id="showOtherLogin">
        <div class="login_account">
          <el-form-item prop="account" label="帳號" class="mb-0 pb-2">
            <el-input type="email" class="inner-input" ref="autoFocus" v-model="account" autocomplete="on" placeholder="請輸入會員Email" />
          </el-form-item>
          <el-form-item prop="password" label="密碼" class="mb-0 pb-3">
            <div class="d-flex align-items-center w-100">
              <el-input type="password" v-model="password" autocomplete="on" class="inner-input w-100" @keydown.enter.prevent="login" show-password placeholder="請輸入密碼" />
              <div class="text-end ms-2 mb-2">
                <router-link to="/login/forgetPassword">
                  <EditButton text="忘記密碼？"></EditButton>
                </router-link>
              </div>
            </div>
          </el-form-item>
        </div>
        <ConfirmButton class="login_btn" @click="login()" text="登入"></ConfirmButton>
      </div>
      <div class="mt-2 d-flex justify-content-center align-items-center">
        <div>沒有帳戶?</div>
        <div class="text-end ms-2">
          <router-link to="/login/verifyEmail">
            <EditButton text="註冊"></EditButton>
          </router-link>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import ConfirmButton from "../../Common/CommonElement/ConfirmButton.vue";
import EditButton from "../../Common/CommonElement/EditButton.vue";
import Storage from "../../../models/storage";
import { googleSdkLoaded } from "vue3-google-login";

export default {
  name: "LoginComponent",
  components: {
    ConfirmButton,
    EditButton,
  },
  data() {
    return {
      account: "",
      password: "",
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.autoFocus.focus());
    this.initFaceBook();
  },
  methods: {
    initFaceBook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FaceBook_CLIENT_ID,
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.FaceBook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "FaceBook-jssdk");
    },
    loginWithFaceBook() {
      window.FB.login(
        response => {
          if (response.authResponse) {
            console.log("成功登录", response.authResponse);
            this.faceBookAuthorize("test");
          } else {
            console.log("登录失败或被取消");
            this.faceBookAuthorize("test");
          }
        },
        { scope: "public_profile,email" }
      );
    },
    async faceBookAuthorize(code) {
      const vm = this;
      if (code == "" || code == null) {
        await vm.$router.push("/login");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/faceBookLogin", par)
        .then(() => {
          let name = vm.$store.state.auth.user.name;
          let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
          vm.notify("success", message);
          vm.$router.push(Storage.get("ChargeMe_Setting").page);
        })
        .catch(() => {
          Storage.remove("user");
          vm.notify("error", "登入失敗！");
        });
    },
    loginWithGoogle() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            redirect_uri: `${process.env.VUE_APP_CURRENT_DOMAIN}`,
            callback: response => {
              if (response.code) {
                this.googleAuthorize(response.code);
              }
            },
          })
          .requestCode();
      });
    },
    async googleAuthorize(code) {
      const vm = this;

      vm.$store.state.isloading = true;
      if (code == "" || code == null) {
        await vm.$router.push("/login");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      //accountBind(快速連結)
      if (user == null && Storage.get("accountBind") != undefined) {
        userId = Storage.get("accountBind").id;
        Storage.remove("accountBind");
      }

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/googleLogin", par)
        .then(() => {
          let name = vm.$store.state.auth.user.name;
          let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
          vm.notify("success", message);
          vm.$router.push(Storage.get("ChargeMe_Setting").page);
        })
        .catch(() => {
          Storage.remove("user");
          vm.notify("error", "登入失敗！");
        })
        .finally(() => {
          vm.$store.state.isloading = false;
        });
    },
    signInWithGoogle() {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CURRENT_DOMAIN.slice(0, -1)}&scope=profile email&response_type=code`;
    },
    lineLogin() {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.VUE_APP_LINE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CURRENT_DOMAIN}LineAuth&state=12345abcde&scope=profile%20openid`;
    },
    async login() {
      const vm = this;
      const user = {
        account: vm.account,
        password: vm.password,
      };
      await vm.$store
        .dispatch("auth/login", user)
        .then(() => {
          let name = vm.$store.state.auth.user.name;
          let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
          vm.notify("success", message);
          vm.$router.push(Storage.get("ChargeMe_Setting").page);
        })
        .catch(err => {
          console.log(err.response.data);
          Storage.remove("user");
          vm.notify("error", `登入失敗，${err.response.data.Msg}!`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  box-shadow: none;
  border: none;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  width: 100%;
  border-radius: 1rem;
}
.login_zone {
  height: 100%;
  padding-top: 0;
  .el-form-item {
    display: block;
    text-align: start;
    margin-bottom: 1rem;
  }
  .login_btn {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    color: white;
    width: 100%;
    padding: 0 !important;
  }
  .login_password {
    margin: 0;
  }
  .other_login {
    display: flex;
    // justify-content: space-around;
    justify-content: center;
    .icon {
      margin: 0.5rem 1.5rem;
    }
  }
}
.icon {
  border-radius: 50%;
  // box-shadow: 1px 1px 3px 1px #b0b0b0;
  overflow: hidden;
  width: 4.5rem;
  height: 4.5rem;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px #565656;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
