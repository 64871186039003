<template>
  <div class="login_center">
    <div class="login">
      <img src="https://chargemestorage.blob.core.windows.net/chargeme-image/喬米LOGO (3).png" alt="logo" class="w-50 mx-auto" @click="$router.push('/')" style="cursor: pointer" />
      <div class="side_nav" v-if="!isRegister && !isResetPassword">
        <img class="w-100" :src="require('/src/assets/image/Shared/Login/AdobeStock_442439035-removebg-preview.png')" />
        <div class="aiplane">
          <img class="w-100" :src="require('/src/assets/image/Shared/Login/earth.png')" />
        </div>
      </div>
      <div class="login_component">
        <BackButton v-if="!isRegister && !isForgetPassword && !isResetPassword && !isVerifyEmail" class="mb-3" text="回首頁" url="/"></BackButton>
        <LoginComponent v-if="isLoginShow" />
        <div v-else>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "../../../models/storage";
import LoginComponent from "@/components/Shared/Login/LoginComponent.vue";
import BackButton from "../../../components/Common/CommonElement/BackButton.vue";

export default {
  name: "Login",
  data() {
    return {};
  },
  components: {
    LoginComponent,
    BackButton,
  },
  mounted() {
    var accountBind = this.$route.query.accountBind;
    if (accountBind != undefined) {
      Storage.set("accountBind", {
        id: accountBind.split("-")[1],
      });
    } else {
      Storage.remove("accountBind");
    }
    this.log_PageInfo("LoginCenter", "11");
  },
  computed: {
    isLoginShow() {
      return this.$route.path === "/login";
    },
    isRegister() {
      return this.$route.path.startsWith("/login/register");
    },
    isForgetPassword() {
      return this.$route.path.startsWith("/login/forgetPassword");
    },
    isResetPassword() {
      return this.$route.path.startsWith("/login/resetPassword");
    },
    isVerifyEmail() {
      return this.$route.path.startsWith("/login/verifyEmail");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  box-shadow: none;
  border: none;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  width: 100%;
  border-radius: 1rem;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* 向上移动10像素 */
  }
  100% {
    transform: translateY(0);
  }
}

/* 应用动画样式 */
.login_center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.login {
  height: 100%;
  padding: 2rem;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bgImg {
    position: relative;
    z-index: -1;
    top: 0;
    // height: 15rem;
  }
  .logo {
    margin-top: -10rem;
    margin-bottom: -3rem;
    font-size: 35px;
    height: 5rem;
    font-weight: 900;
    color: #7b7b7b;
  }
  .side_nav {
    margin-top: 3rem;
    position: relative;
    border-radius: 0px 0px calc(100% - 50vw) 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #fff;
      font-size: 5rem;
      font-weight: 900;
      margin-right: 1rem;
    }
    p {
      text-align: start;
      color: #fff;
      font-size: 1.5rem;
      width: 13rem;
    }
    .aiplane {
      animation: floatAnimation 3s ease infinite;
      position: absolute;
      width: 45%;
      top: 5%;
      left: 1%;
    }
  }
  .backHome {
    display: block;
    text-align: start;
    margin-bottom: 1rem;
    color: #afafaf;
    font-size: 1rem;
    span {
      color: #afafaf;
      // font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }
  .login_component {
    // margin-top: auto;
  }
}

@media screen and (min-width: 768px) {
  .login_center {
    background-color: #fffdf8;
  }
  .login {
    width: 100%;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    margin: 0 auto;
    justify-content: center;
    height: unset;
    .logo {
      margin-top: 0;
      margin-bottom: 0;
    }
    .bgImg {
      border-radius: 0px 0px 50% 0%;
      max-width: 400px;
      height: 10rem;
    }
  }
}
</style>
