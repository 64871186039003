<template>
  <el-button>{{ text }}</el-button>
</template>

<script>
export default {
  name: "EditButton",
  props: ["text"],
};
</script>

<style lang="scss" scoped>
button {
  font-size: 0.85rem;
  font-weight: 500;
  color: $edit_button_color;
  background: transparent;
  border: none;
  text-decoration: underline;
  transition: 0.2s;
  padding: 0;
  &:hover {
    color: $edit_button_hover_color;
    background: transparent;
  }
}
</style>
